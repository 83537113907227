import { useProduct } from '@hooks';
import { useContext } from 'react';
import { CartContext } from '@global/CartContext';
import { getCartProduct } from '@utils/product';
import { getCartProductQuantity } from '@utils/product';
import { CURRENCY } from '@utils/constant';
import { event } from '@utils/analytics';
import cogoToast from 'cogo-toast';

const AddToCartButton = ({ product, productQuantity = 1, replace, badge, discount, category, className }) => {
  const {
    productSize,
    productColor,
    productPrice,
    discountedPrice,
    productStock,
    productMaterial,
    modalCartAddedHandler,
  } = useProduct(product);

  const { shoppingCart, addToCart } = useContext(CartContext);

  const isInCart = getCartProduct(
    shoppingCart,
    product,
    productColor,
    productSize,
    productMaterial
  );

  const cartProductQuantity = getCartProductQuantity(
    shoppingCart,
    product,
    productColor,
    productSize
  );

  const actionText = determineAction(replace, productStock, cartProductQuantity);

  return (
    <button
      disabled={productStock < 1}
      className={`${className ?? ''} btn ${productStock === cartProductQuantity ? 'disable' : ''}`}
      onClick={() => {

        if (replace) {
          replace({
            ...product,
            price: discountedPrice ? discountedPrice : productPrice,
            color: productColor,
            size: productSize,
            material: productMaterial,
            quantity: productQuantity,
          });

          return cogoToast.success('Tu Item fue reemplazado.', {
            position: 'bottom-right',
            heading: 'Agregado al carrito!',
            hideAfter: 4,
          });
        }

        event({
          action: 'add_to_cart',
          params: {
            currency: 'USD',
            value: discountedPrice ? discountedPrice : productPrice,
            item: {
              item_id: badge,
              item_name: name,
              affiliation: '',
              coupon: '',
              currency: CURRENCY,
              creative_name: '',
              discount: discount,
              item_category: category,
              item_color: productColor,
              item_size: productSize,
              item_material: productMaterial,
              quantity: productQuantity,
            },
          },
        });

        addToCart({
          ...product,
          price: discountedPrice ? discountedPrice : productPrice,
          color: productColor,
          size: productSize,
          material: productMaterial,
          quantity: productQuantity,
        });

        !isInCart
          ? modalCartAddedHandler()
          : cogoToast.success(`Tu Item "${product.name}" fue agregado.`, {
              position: 'bottom-right',
              heading: 'Agregado al carrito!',
              hideAfter: 4,
            });
      }}
    >
      <i className="icon-f-39"></i>
      {actionText}
    </button>
  );
};

const determineAction = (replace, productStock, cartProductQuantity) => {
  if (productStock - cartProductQuantity <= 0 ) {
      return 'SIN STOCK';
  } else if (replace) {
      return 'REEMPLAZAR';
  }  else {
      return 'AGREGAR AL CARRITO';
  }     
}

export default AddToCartButton;
